import "./index.scss";
function Intro() {   
    return (
        <div className="intro_container">
            <div className="text">
                <h1>關於我們</h1>
                <h1>About US</h1>
                <p>
                2008 年，比特幣白皮書與我們初次見面。2021 年，它的價格達到六萬九千美元。
                從無到有，區塊鏈技術的成長離不開越來越多的開發者。
                我們希望能借此社團，引領大家對區塊鏈技術的探索與研究。
                我們會提供多元的資訊，不管你是區塊鏈新手，或是熟練的開發者，都能在這裡找到自己的方向。
                <br></br>
                我們建立的宗旨是想要匯集業界、學生、校友、教授等各方面成員,
                期望透過社團成員的多樣性，跨領域、年齡為社團帶來多樣性。
                藉由講師分享、社團分享，讓想進入區塊鏈社團的校內外人士,
                了解北科區塊鏈研究社的社團活動及產業界的資訊,並活絡校園討論風氣。
                </p>
                </div>
        </div>
    );
}

export default Intro;